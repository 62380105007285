import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Span } from "@icg360/design-system";

import { AuthAppDispatchContext } from "components/root/auth-app-provider";
import { ENROLLMENT_STATUS, PAYMENT_METHOD_CODE } from "consts";
import {
  type PolicyPaymentDataQuery,
  usePolicyPaymentDataQuery,
} from "gql/__generated__/hooks";
import { type Policy } from "gql/types";
import EditBillingEmail from "pages/settings/common/edit-billing-email";
import SettingsPolicy, {
  type SettingsPolicyDetailItem,
} from "pages/settings/common/settings-policy";
import {
  formatAddress,
  getMortgageeClass,
  getPaymentMethodDetails,
  isEasyPayEligible,
  isEasyPayEnrolled,
} from "utils";

import EditMortgagee from "./edit-mortgagee";
import styles from "./payment-item.module.scss";

const getMortgageeItems = (
  mortgagees: NonNullable<PolicyPaymentDataQuery["userBilling"]>["mortgagees"],
  policyId: string,
  keystonePolicyId: string
): SettingsPolicyDetailItem[] => {
  let detailsItems: SettingsPolicyDetailItem[] = [];
  mortgagees?.forEach((mortgagee) => {
    const { name, loanNumber, index, ...rest } = mortgagee;
    detailsItems = detailsItems.concat([
      {
        label: `${getMortgageeClass(index)} mortgagee:`,
        value: (
          <>
            {name}
            <EditMortgagee
              mortgagee={mortgagee}
              policyId={policyId}
              keystonePolicyId={keystonePolicyId}
            />
          </>
        ),
      },
      {
        label: "Billing address:",
        value: formatAddress(rest),
      },
      {
        label: "Loan number:",
        value: loanNumber,
      },
    ]);
  });
  return detailsItems;
};

const getDirectOrEasyPayItems = (
  policy: Policy,
  paymentFrequency: string,
  userBillingAddress: string
): SettingsPolicyDetailItem[] => {
  return [
    { label: "Payment frequency:", value: paymentFrequency },
    {
      label: "Billing email:",
      value: (
        <>
          {userBillingAddress ||
            "No billing email address associated with this policy"}
          {userBillingAddress && (
            <EditBillingEmail policyId={policy?.policyId ?? ""} />
          )}
        </>
      ),
    },
  ];
};

const getDetailItems = (
  policy: Policy,
  userDetails: PolicyPaymentDataQuery["userDetails"],
  userBilling: PolicyPaymentDataQuery["userBilling"],
  userBillingAddress: PolicyPaymentDataQuery["userBillingAddress"]
): SettingsPolicyDetailItem[] => {
  let detailsItems: SettingsPolicyDetailItem[] = [
    { label: "Policy number:", value: policy.policyId },
  ];

  if (!userDetails || !userBilling) {
    return detailsItems;
  }

  const { keystonePolicyId, insuredPreferences } = userDetails;

  const { accounting, mortgagees } = userBilling;

  const { paymentMethod, paymentMethodLabel, paymentFrequencyLabel } =
    getPaymentMethodDetails(accounting?.paymentPlan?.planType?.toLowerCase());

  const easyPayEnrolled = isEasyPayEnrolled(userBilling);
  const isMortgagee = paymentMethod === PAYMENT_METHOD_CODE.INVOICE;

  const pendingEasyPayEnrollment =
    !easyPayEnrolled &&
    insuredPreferences?.easyPayEnrollmentStatus === ENROLLMENT_STATUS.PENDING;
  const showEditPaymentMethod = !isMortgagee && !pendingEasyPayEnrollment;

  detailsItems.push({
    label: "Payment method:",
    value: (
      <>
        {paymentMethodLabel}
        {showEditPaymentMethod && <EditPaymentButton policy={policy} />}
      </>
    ),
  });

  detailsItems = detailsItems.concat(
    isMortgagee
      ? getMortgageeItems(mortgagees, policy.policyId, keystonePolicyId ?? "")
      : getDirectOrEasyPayItems(
          policy,
          paymentFrequencyLabel,
          userBillingAddress ?? ""
        )
  );

  return detailsItems;
};

type PaymentItemProps = {
  policy: Policy;
  setEasyPayBanner: Dispatch<SetStateAction<boolean>>;
};

const PaymentItem = ({ policy, setEasyPayBanner }: PaymentItemProps) => {
  const { data: PolicyPaymentData, loading } = usePolicyPaymentDataQuery({
    variables: {
      policyID: policy?.policyId ?? "",
    },
  });
  const { userDetails, userBilling, userBillingAddress } =
    PolicyPaymentData ?? {};
  const { propertyAddress, policyId, isCommercial } = policy;

  useEffect(() => {
    if (
      userBilling &&
      userDetails &&
      isEasyPayEligible(userBilling, userDetails)
    ) {
      setEasyPayBanner(true);
    }
  }, [setEasyPayBanner, loading, userBilling, userDetails]);

  const detailItems =
    !loading && policyId
      ? getDetailItems(policy, userDetails, userBilling, userBillingAddress)
      : [];

  return (
    <SettingsPolicy
      isCommercial={!!isCommercial}
      address={propertyAddress}
      detailItems={detailItems}
      loading={loading || !policyId}
    >
      {!userDetails || !userBilling ? (
        <Span>An error occured while fetching this data</Span>
      ) : null}

      {isEasyPayEligible(userBilling, userDetails) ? (
        <EnrollInEasyPayButton policy={policy} />
      ) : null}
    </SettingsPolicy>
  );
};

export default PaymentItem;

const EditPaymentButton = ({ policy }: { policy: Policy }) => {
  const navigate = useNavigate();
  const { setPolicy } = useContext(AuthAppDispatchContext);

  return (
    <Button
      appearance="tertiary"
      size="xs"
      onClick={() => {
        setPolicy(policy);
        navigate("/my/billing/update?location=settings", {
          replace: true,
        });
      }}
      data-testid="payment-method-edit-button"
    >
      Edit
    </Button>
  );
};

const EnrollInEasyPayButton = ({ policy }: { policy: Policy }) => {
  const navigate = useNavigate();
  const { setPolicy } = useContext(AuthAppDispatchContext);

  return (
    <div className={styles.easyPayLink}>
      <Button
        appearance="secondary"
        leadingIcon="Money"
        onClick={() => {
          setPolicy(policy);
          navigate("/my/billing/update?location=settings", {
            replace: true,
          });
        }}
        data-testid="enroll-in-easypay-button"
      >
        Enroll in EasyPay
      </Button>
    </div>
  );
};
