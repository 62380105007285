import { useCallback, useEffect, useState } from "react";

import { addToast } from "@icg360/design-system";

import { CONFIG } from "config";
import { useContactItemDataQuery } from "gql/__generated__/hooks";
import { en as locale } from "locale";
import { KeystoneApiClient, isError, useFlags } from "utils";

const keystone = new KeystoneApiClient(CONFIG.KEYSTONE_API_HREF);

export const useUpdateForm = (infoItem) => {
  const [showForm, setShowForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();

  const handleShowForm = () => setShowForm(true);
  const handleCancelForm = () => setShowForm(false);
  const handleContactInfoUpdateSuccess = () => {
    addToast(locale.settingsPage.toastMessages.success[infoItem], {
      icon: true,
    });
    setShowForm(false);
  };
  const handleContactInfoUpdateError = () => {
    setErrorMessage(locale.settingsPage.toastMessages.error);
  };
  const handleErrorDismiss = () => setErrorMessage(null);

  return {
    showForm,
    errorMessage,
    handleShowForm,
    handleCancelForm,
    handleContactInfoUpdateError,
    handleContactInfoUpdateSuccess,
    handleErrorDismiss,
  };
};

export function usePolicyHolderPhone(policyId: string) {
  const [policyHolderPhone, setPolicyHolderPhone] = useState<string>();
  const [restLoading, setRestLoading] = useState(false);
  const { retireProxy, universalLogin } = useFlags();

  const { data: claimsConstantsData, loading } = useContactItemDataQuery({
    variables: {
      policyID: policyId ?? "",
    },
  });

  const doClaimConstantsQuery = useCallback(async () => {
    setRestLoading(true);
    const response = await keystone.getClaimConstants(policyId);
    setRestLoading(false);
    if (!isError(response)) {
      setPolicyHolderPhone(response?.PolicyHolderDetails?.phoneNumber);
    }
  }, [policyId]);

  useEffect(() => {
    if (retireProxy && universalLogin) {
      doClaimConstantsQuery();
    } else {
      setPolicyHolderPhone(
        claimsConstantsData?.userClaimsConstants?.PolicyHolderDetails
          ?.phoneNumber
      );
    }
  }, [
    doClaimConstantsQuery,
    claimsConstantsData?.userClaimsConstants?.PolicyHolderDetails,
    retireProxy,
    universalLogin,
  ]);

  const refreshPhone = useCallback(async () => {
    if (retireProxy && universalLogin) {
      await doClaimConstantsQuery();
    }
  }, [doClaimConstantsQuery, retireProxy, universalLogin]);

  return {
    policyHolderPhone,
    phoneLoading: retireProxy && universalLogin ? restLoading : loading,
    refreshPhone,
  };
}
